<template>
  <div class="hero container">
    <div class="content">
      <div class="hero__content">
        <h1>
          Connecting State and Local
          <span class="bottom-line">Government Leaders</span>
        </h1>

        <p>Solve challenges with your peers across the country</p>

        <ButtonV2 variant="solid" href="#benefits"
          >Membership Benefits</ButtonV2
        >
      </div>
    </div>
  </div>
</template>

<script>
// TODO: Add scroll to next section when button is clicked
import ButtonV2 from '~/components/ButtonV2.vue'

export default {
  components: {
    ButtonV2,
  },
}
</script>

<style lang="scss" scoped>
.hero {
  padding: 69px 32px;
  margin: 0 10px;
  background-image: url('/img/home/homepage-hero-mobile.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  justify-items: flex-start;
  border-radius: 6px;

  @include retina() {
    background-image: url('/img/home/homepage-hero-mobile@2x.jpg');
  }

  @include bp('sm') {
    width: calc(100% - 20px);
    margin: auto;
    padding: 6rem;
    background-image: url('/img/home/homepage-hero.jpg');

    @include retina() {
      background-image: url('/img/home/homepage-hero@2x.jpg');
    }
  }

  @include bp('md') {
    padding: 8rem;
  }
}

.hero__content {
  color: #ffffff;
  max-width: 500px;
  margin: auto;

  @include bp('sm') {
    max-width: none;
    text-align: center;
  }
}

p {
  margin: 0 0 18px;
  color: #ffffff;
  font-size: 18px;

  @include bp('sm') {
    margin-bottom: 2em;
    font-size: 24px;
    line-height: 33px;
  }
}

h1 {
  margin-bottom: 1rem;
}

.bottom-line {
  @include bp('sm') {
    display: block;
  }

  @include bp('lg') {
    display: inline;
  }
}
</style>

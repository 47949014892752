
import Vue from 'vue'
import ButtonV2 from '~/components/ButtonV2'
import generateSrcSet from '~/utils/generateSrcSet'

export default Vue.extend({
  components: {
    ButtonV2,
  },
  props: [
    'title',
    'contentText',
    'btnHref',
    'btnTo',
    'btnText',
    'id',
    'img',
    'btnCallback',
  ],
  data() {
    return {
      isRetinaDevice: false,
    }
  },
  computed: {
    imgSrcset(): string {
      return generateSrcSet(this.img)
    },
  },
})


import Vue from 'vue'
import Hero from '~/components/home/Hero.vue'
import Quote from '~/components/home/Quote.vue'
import TwoColumn from '~/components/home/TwoColumn.vue'
import Partnerships from '~/components/home/Partnerships.vue'

export default Vue.extend({
  auth: false,
  components: {
    Hero,
    Quote,
    TwoColumn,
    Partnerships,
  },
  data() {
    return {
      benefitSections: [
        {
          title: 'Peer Networking Roundtables',
          content: [
            '<p>Discover best practices, exchange insights, and gather resources to address emerging challenges in state and local government. Whether held virtually or in-person, these peer networking roundtables foster collaboration and break down silos to connect leaders tackling similar issues from diverse perspectives nationwide.</p>',
          ],
          btnLink: '/join',
          btnText: 'Join for Free',
          id: 'benefits',
          fromPlacement: 'roundtables',
          img: '/img/home/community/community-roundtable-map.webp',
          quote: {
            text: 'Thank you for hosting these roundtables! You’re always welcome to keep me on your list and include me on any of these conversation events/meetings/etc, I find them very valuable and would love to participate more.',
            name: 'Ginger LePage',
            title: 'Director of Technology for the City of Dunwoody, GA',
            img: '/img/home/community/testimonial-roundtable.webp',
            imgAlt: 'Ginger LePage',
          },
        },
        {
          title: 'Resources & Dedicated Helpline',
          content: [
            '<p>Explore handbooks, toolkits, and guides crafted by and for state and local government leaders. Need something specific? Turn to our helpline! Our community team ensures you have the right information to make quicker and better-informed decisions.</p>',
          ],
          btnLink: '/join',
          btnText: 'Join for Free',
          id: 'resources',
          fromPlacement: 'resources',
          img: '/img/home/community/community-resources.webp',
          quote: {
            text: 'Thanks so much for reaching out and sending these articles. These were actually really timely, and gave me some inspiration. Thank you!',
            name: 'Kathleen Kennedy',
            title:
              'Assistant Township Administrator at Springfield Township, Hamilton County, Ohio',
            img: '/img/home/community/testimonial-resources.webp',
            imgAlt: 'Kathleen Kennedy',
          },
        },
        {
          title: 'Help Writing Case Studies',
          content: [
            `<p>Do you have a standout project that could inspire other state and local governments? Let's showcase it! Collaborate with our team of writers to expand the community case study database, and help other leaders find proven solutions to similar challenges.</p>`,
          ],
          btnLink: '/join',
          btnText: 'Join for Free',
          id: 'case-studies',
          fromPlacement: 'case-studies',
          img: '/img/home/community/community-case-studies.webp',
          quote: {
            text: 'It is definitely an honor for us and sharing information about The Atlas even within our organization has been a big win for us. So many departments are interested in learning more and asking us to help them submit case studies. Again, we really appreciate the support and are looking forward to the continued work together.',
            name: 'Sabrina Abdulla',
            title: 'Director of Customer Experience, Sugarland, TX',
            img: '/img/home/community/testimonial-case-studies.webp',
            imgAlt: 'Sabrina Abdulla',
          },
        },
      ],
    }
  },
  mounted() {
    if (this.$track) this.$track.page()
  },
  methods: {
    handleJoinClick(fromPlacement: string) {
      if (this.$track) {
        this.$track.event({
          segment: {
            action: 'Join Clicked',
            data: {
              from: `home-section-cta-${fromPlacement}`,
            },
          },
        })
      }

      window.location.href = '/join'
    },
  },
})


import Vue, { PropType } from 'vue'
import generateSrcSet from '~/utils/generateSrcSet'

type Quote = {
  text: string
  name: string
  title: string
  img: string
  imgAlt: string
}

export default Vue.extend({
  props: {
    quote: {
      type: Object as PropType<Quote>,
      required: true,
    },
  },
  // make srcset for image
  computed: {
    imgSrcset(): string {
      return generateSrcSet(this.quote.img)
    },
  },
})
